export const ITEMS_PER_PAGE = 10;
export const MAX_ITEMS_PER_PAGE = 5000;
export const MIN_PIN_LENGTH = 4;
export const MIN_PASSWORD_LENGTH = 8;
export const DEFAULT_LANGUAGE = 'en';
export const HOME_WEBSITE = 'https://yesway.money/';
export const CASHPOINT_HOME_WEBSITE = 'https://yesway.money/cashpoints';
export const CLIENT_APP_WEBSITE = 'https://app.yesway.money';
export const TELLER = 'teller';
export const MANAGER = 'manager';
export const CREDIT = 'credit';
export const DEBIT = 'debit';
export const MEDIUM_SCREEN = 999;
export const SMALL_SCREEN = 776;
